import {useEffect, useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
// hooks
import {useAuth, useUser} from 'core/hooks';
// components
import {
  Box,
  Alert,
  Stack,
  useTheme,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  Button,
  DialogBox,
  StaticBanner,
  AppBreadcrumb,
  ErrorBoundary,
  LayoutContainer,
  QualifyingPrograms,
} from 'mui-core';
import {About, RelatedCareers} from './subModules';
// redux
import {
  getGoSurveyResultDetails,
  fetchGoSurveyResultDetails,
  goSurveyResultDetailsClear,
} from 'redux/modules/survey';
import {
  getSavedCareers,
  subscribeCareer,
  fetchSavedCareers,
  unSubscribeCareer,
  clearSavedCareerListError,
} from 'redux/modules/career-destination';
import {openLoginScreen} from 'redux/modules/auth/actions';
import {NewJobs, Apprenticeships} from 'app/modules/opportunities';
//utils
import {
  Check,
  Share,
  Favorite,
  ArrowBack,
  FavoriteBorder,
} from '@mui/icons-material';
import {useSnackbar} from 'notistack';
import {getGoBanner} from 'mui-core/utils';
import CopyToClipboard from 'react-copy-to-clipboard';
// json
import {goSurveyResults} from 'data/goSurvey.json';

const {
  jobsTab,
  tabsTitles,
  programsTab,
  shareSuccessMsg,
  apprenticeshipsTab,
  saveCareer: {
    errorMsg,
    popupTitle,
    cancelButton,
    confirmButton,
    alreadySavedMsg,
    popupDescription,
  },
} = goSurveyResults;
const tabKeys = [
  'about',
  'programs',
  'jobs',
  'apprenticeships',
  'related careers',
];

const CareerDetails = () => {
  // hooks
  const theme = useTheme();
  const [token] = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {data: userData = {}} = useUser() || {};
  const [activeTab, setActiveTab] = useState(0);
  const [checkSave, setCheckSave] = useState(false);
  const {career_name = '', cip_code = ''} = useParams();
  const careerName = decodeURIComponent(career_name) || '';
  const [confirmationModal, setConfirmationModal] = useState(false);
  const isMediumDevice = useMediaQuery(theme.breakpoints.down('lg'));
  // selectors
  const savedCareerData = useSelector(getSavedCareers) || {};
  const goSurveyResultDetails = useSelector(getGoSurveyResultDetails) || {};
  // destructuring
  const {student_uuid = ''} = userData || {};
  const {request: goSurveyResultDetailsRequest, data = {}} =
    goSurveyResultDetails || {};
  const {data: savedCareersList = [], request: savedCareersListRequest} =
    savedCareerData || {};
  const {tagline = '', occupation_details = {}} = data?.description || {};
  const {cip4_image_cloudinary = '', occupation_onnet = ''} =
    occupation_details || {};
  const isCareerSaved =
    savedCareersList &&
    Array.isArray(savedCareersList) &&
    savedCareersList.length > 0 &&
    savedCareersList.some(
      career =>
        career?.career_name === careerName && career?.cip_code === cip_code,
    );

  const dataset = {
    data: data,
    request: goSurveyResultDetailsRequest,
  };

  const {
    location: {pathname},
  } = history;
  const pathSegments = pathname.split('/');
  const urlToBeCopied = `${window.location.href}`;
  const lastSegment = pathSegments[pathSegments.length - 1];
  const activeView = lastSegment || 'about';

  const tabs = useMemo(() => {
    let tabsList = [];
    tabsList.push({
      key: tabKeys[0],
      tabName: tabsTitles[0],
      component: <About goSurveyResultDetails={goSurveyResultDetails} />,
    });
    tabsList.push({
      key: tabKeys[1],
      tabName: tabsTitles[1],
      component: (
        <QualifyingPrograms
          disableBGcolor
          // cipCode={cip_code}
          applyRouting={false}
          title={programsTab.title}
          occupationName={career_name}
          noResults={programsTab.noResults}
        />
      ),
    });
    tabsList.push({
      key: tabKeys[2],
      tabName: tabsTitles[2],
      component: (
        <NewJobs
          tabPage={true}
          disableBGcolor
          title={jobsTab.title}
          search_query={decodeURIComponent(careerName || '')}
        />
      ),
    });
    tabsList.push({
      key: tabKeys[3],
      tabName: tabsTitles[3],
      component: (
        <Apprenticeships
          disableBGcolor
          tabPage={true}
          onetCode={occupation_onnet}
          title={apprenticeshipsTab.title}
        />
      ),
    });
    tabsList.push({
      key: tabKeys[4],
      tabName: tabsTitles[4],
      component: (
        <RelatedCareers
          disableBGcolor
          cipCode={cip_code}
          careerName={careerName}
        />
      ),
    });
    return tabsList;
  }, [data?.description, activeTab]);

  useEffect(() => {
    careerName &&
      cip_code &&
      dispatch(fetchGoSurveyResultDetails(careerName, cip_code));
    return () => dispatch(goSurveyResultDetailsClear());
  }, [careerName, cip_code]);

  useEffect(() => {
    // Clear student saved careers on logout
    return () => {
      dispatch(clearSavedCareerListError());
    };
  }, [student_uuid]);

  useEffect(async () => {
    if (checkSave && student_uuid) {
      const userSavedCareers = await dispatch(fetchSavedCareers(student_uuid));
      const presentCareerSaved =
        userSavedCareers &&
        Array.isArray(userSavedCareers) &&
        userSavedCareers.length > 0 &&
        userSavedCareers.some(
          career =>
            career?.career_name === careerName && career?.cip_code === cip_code,
        );
      if (presentCareerSaved) {
        enqueueSnackbar(alreadySavedMsg, {
          variant: 'warning',
          children: () => {
            return (
              <Alert
                icon={<Check fontSize='inherit' />}
                sx={{width: '100%', color: 'white'}}
              />
            );
          },
        });
      } else {
        onSaveCareer();
      }
      setCheckSave(false);
    } else {
      student_uuid &&
        !savedCareersList &&
        dispatch(fetchSavedCareers(student_uuid));
    }
  }, [checkSave, student_uuid]);

  useEffect(() => {
    if (tabs.length) {
      const idx = tabs.findIndex(x => x.key === activeView);
      if (activeView && activeTab !== idx) {
        if (idx !== -1) {
          setActiveTab(idx);
        }
      }
    }
  }, [activeView, tabs]);

  const onTabChange = (e, index) => {
    const tabName = e?.target?.textContent?.toString()?.toLowerCase();
    const idx = tabs.findIndex(x => x?.key === tabName);
    const url = `/career-details/${careerName}/${cip_code}/${tabs[idx]?.key}`;
    history.push(url);
    const upperCase =
      tabKeys[idx].charAt(0).toUpperCase() + tabKeys[idx].slice(1);
    const newIndex = tabs.findIndex(tab => tab.title == upperCase);
    if (tabKeys[idx] && activeTab !== newIndex) {
      if (newIndex !== -1) {
        setActiveTab(newIndex);
      }
    }
  };

  const handleCopy = () => {
    enqueueSnackbar(shareSuccessMsg, {
      variant: 'success',
      children: () => {
        return (
          <Alert
            icon={<Check fontSize='inherit' />}
            sx={{width: '100%', color: 'white'}}
          />
        );
      },
    });
  };

  const unSaveCareer = () => {
    const {id: careerId = ''} = savedCareersList.find(
      career =>
        career?.career_name === careerName && career?.cip_code === cip_code,
    );

    careerId &&
      dispatch(
        unSubscribeCareer(careerId, res => {
          if (res?.Success) {
            enqueueSnackbar(res?.Success, {
              variant: 'success',
            });
            dispatch(fetchSavedCareers(student_uuid));
          } else {
            enqueueSnackbar(errorMsg, {
              variant: 'waring',
            });
          }
        }),
      );
    setConfirmationModal(false);
  };

  const onSaveCareer = () => {
    const data = {
      cip_code: cip_code,
      career_name: careerName,
      student_uuid: student_uuid,
      cip4_image_cloudinary: cip4_image_cloudinary,
    };

    if (!isCareerSaved && student_uuid) {
      {
        dispatch(
          subscribeCareer(data, res => {
            if (res?.Success) {
              enqueueSnackbar(res?.Success, {
                variant: 'success',
                children: () => {
                  return (
                    <Alert
                      icon={<Check fontSize='inherit' />}
                      sx={{width: '100%', color: 'white'}}
                    />
                  );
                },
              });
              dispatch(fetchSavedCareers(student_uuid));
            } else {
              enqueueSnackbar(errorMsg, {
                variant: 'waring',
              });
            }
          }),
        );
      }
    } else {
      token && setConfirmationModal(true);
    }
  };

  const onSaveJobClick = () => {
    if (token) {
      onSaveCareer();
    } else {
      dispatch(
        openLoginScreen({
          callback: () => {
            setCheckSave(true);
          },
        }),
      );
    }
  };

  return (
    <ErrorBoundary nameOfComponent='career-details'>
      <Container maxWidth='xl'>
        <AppBreadcrumb
          dataList={[
            {
              name: 'Careers',
              path: '/careers',
            },
            {
              name: decodeURIComponent(careerName || ''),
            },
          ]}
        />
      </Container>
      <LayoutContainer
        centeredTab
        tabArr={tabs}
        disableBGcolor
        enableFullWidth
        dataSet={dataset}
        showSearch={false}
        typeOfUi='subPage'
        showDivider={false}
        showFilterBtn={false}
        activeTab={tabKeys[activeTab]}
        nameOfComponent='careers details'
        onChange={(e, index) => onTabChange(e, index)}
        banner={
          <>
            <StaticBanner
              desc={tagline}
              titleVariant='title55'
              descVariant='pagedesc'
              title={careerName || ''}
              url={getGoBanner(cip4_image_cloudinary)}
            />
            <Container maxWidth='xl'>
              <Stack
                mt={1}
                mb={-2}
                columnGap={2}
                direction='row'
                justifyContent='end'>
                <IconButton
                  aria-label='goBack'
                  title='Previous Page'
                  onClick={() => history.goBack()}
                  size={isMediumDevice ? 'medium' : 'large'}>
                  <ArrowBack fontSize='inherit' />
                </IconButton>
                <CopyToClipboard
                  id='share-button'
                  onCopy={handleCopy}
                  text={urlToBeCopied}>
                  <IconButton
                    title='Share URL'
                    aria-label='share'
                    size={isMediumDevice ? 'medium' : 'large'}>
                    <Share fontSize='inherit' />
                  </IconButton>
                </CopyToClipboard>
                <IconButton
                  title='Save'
                  aria-label='save'
                  onClick={onSaveJobClick}
                  disabled={savedCareersListRequest}
                  size={isMediumDevice ? 'medium' : 'large'}
                  color={isCareerSaved && token ? 'success' : 'black'}>
                  {isCareerSaved && token ? (
                    <Favorite fontSize='inherit' />
                  ) : (
                    <FavoriteBorder fontSize='inherit' />
                  )}
                </IconButton>
              </Stack>
            </Container>
          </>
        }
        loadingTitle='Please wait while we are fetching the career details'
      />
      <DialogBox
        openDialog={confirmationModal}
        closeDialog={() => setConfirmationModal(false)}
        actions={
          <>
            <Button
              variant='outlined'
              sx={{minWidth: {xs: '80px', md: '168px'}}}
              onClick={() => setConfirmationModal(false)}>
              {cancelButton}
            </Button>

            <Button
              variant='contained'
              onClick={unSaveCareer}
              sx={{minWidth: {xs: '80px', md: '168px'}}}>
              {confirmButton}
            </Button>
          </>
        }>
        <Typography mb={2} variant='h4' textAlign='center' color='text.primary'>
          {popupTitle}
        </Typography>
        <Typography
          component='p'
          variant='bodyL'
          textAlign='center'
          color='text.primary'>
          {popupDescription}{' '}
          <Typography
            variant='h6'
            component='span'
            color={theme.palette.primary.main}>
            {careerName}
          </Typography>
          ?
        </Typography>
      </DialogBox>
    </ErrorBoundary>
  );
};

export default CareerDetails;
