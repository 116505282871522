import React, {useState, useEffect, useCallback} from 'react';
import {LayoutContainer} from 'mui-core';
import {
  fetchRelatedCareers,
  getRelatedCareers,
} from 'redux/modules/career-destination';
import RelatedCareersLayout from './Layout';
import {useDispatch, useSelector} from 'react-redux';
import careerData from 'data/exploreCareer.json';

const {
  relatedCareers: {searchPlaceholder},
} = careerData;

const defaultParams = {
  page: 1,
  page_size: 16,
};

const RelatedCareers = ({disableBGcolor = false, cipCode, careerName}) => {
  const dispatch = useDispatch();
  const [reqParams, setReqParams] = useState(defaultParams);
  const {request = false, data: relatedCareerData = {}} = useSelector(
    getRelatedCareers,
  );
  const {
    data: relatedCareerList = [],
    page_details: pageOptions = {},
  } = relatedCareerData;

  useEffect(() => {
    refetchData({...reqParams, cip_code: cipCode, career_name: careerName});
  }, []);

  const refetchData = useCallback(params => {
    setReqParams(params);
    dispatch(fetchRelatedCareers(params));
  });

  const onPageChange = page => {
    window.scrollTo({top: 660, behavior: 'smooth'});
    const updatedParams = {...reqParams, page};
    refetchData(updatedParams);
  };

  const onFinish = useCallback(
    value => {
      const params = {...reqParams, ...value};
      refetchData(params);
    },
    [reqParams],
  );

  const onFilterReset = resetParams => {
    const Obj = {...reqParams, ...resetParams};
    refetchData(Obj);
  };

  return (
    <LayoutContainer
      typeOfUi='subPage'
      showDivider={false}
      showFilterBtn={false}
      showResultCount={false}
      disableBGcolor={disableBGcolor}
      nameOfComponent='related careers'
      dataSet={{request, data: relatedCareerData}}
      searchProps={{
        onFinish,
        onFilterReset,
        name: 'search',
        filters: reqParams,
        searchPlaceholder: searchPlaceholder,
      }}>
      <RelatedCareersLayout
        reqParams={reqParams}
        careerRequest={request}
        pageOptions={pageOptions}
        onPageChange={onPageChange}
        relatedCareerList={relatedCareerList}
      />
    </LayoutContainer>
  );
};

export default RelatedCareers;
