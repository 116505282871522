import {useEffect} from 'react';
import {
  FilterDrawer,
  LocationSearch as LocationSearchComponent,
} from 'mui-core';
import {useForm} from 'react-hook-form';
import {createField} from 'mui-core/Form';
import {styled} from '@mui/material/styles';
import {Stack, Select, MenuItem} from '@mui/material';
import {exploreCareer} from 'data/exploreCareer.json';

const {careerAreaLabel, defaultCareerArea} = exploreCareer;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const StyledCompaniesFilter = styled(Stack)(({theme}) => ({
  width: '100%',
  [theme.breakpoints.only('sm')]: {
    width: '50%',
  },
  [theme.breakpoints.only('xs')]: {
    minWidth: '100%',
  },
}));

const FilterForm = ({
  filters,
  onFinish,
  isDrawerOpen,
  onDrawerClose,
  onFilterReset,
  careerAreaList,
}) => {
  const form = useForm();
  const {reset, handleSubmit, setValue, getValues} = form;

  const onResetClick = () => {
    const resetObj = {
      cluster_uuid: 'all',
      location: 'United States',
    };
    onFilterReset(resetObj);
    reset(resetObj);
  };

  useEffect(() => {
    if (filters?.location) {
      setValue('location', filters.location);
    }
  }, [filters]);

  const handleLocationChange = value => {
    setValue('location', value.location);
  };

  const onInputChange = value => {
    setValue('location', value.location);
  };

  return (
    <StyledCompaniesFilter display={isDrawerOpen ? 'block' : 'none'}>
      <FilterDrawer
        filters={filters}
        onReset={onResetClick}
        onClose={onDrawerClose}
        openFilter={isDrawerOpen}
        handleSubmit={handleSubmit(onFinish)}>
        <Stack spacing={3}>
          {careerAreaList &&
            Array.isArray(careerAreaList) &&
            createField('cluster_uuid', {
              form,
              label: careerAreaLabel,
              defaultValue: getValues('cluster_uuid') || 'all',
            })(
              <Select
                defaultValue='all'
                MenuProps={menuProps}
                label={careerAreaLabel}
                aria-label={careerAreaLabel}
                inputProps={{id: careerAreaLabel}}>
                <MenuItem value='all' key='all'>
                  {defaultCareerArea}
                </MenuItem>
                {careerAreaList.map(option => (
                  <MenuItem
                    key={option.cluster_uuid}
                    value={option.cluster_uuid}>
                    {option.cluster_name}
                  </MenuItem>
                ))}
              </Select>,
            )}
          {createField('location', {
            form,
            defaultValue: getValues('location') || '',
          })(
            <LocationSearchComponent
              onBlur={null}
              calledFromForm
              onClear={null}
              onEnterPress={() => {}}
              onInputChange={onInputChange}
              onLocationChange={handleLocationChange}
              filters={{location: getValues('location')}}
            />,
          )}
        </Stack>
      </FilterDrawer>
    </StyledCompaniesFilter>
  );
};

export default FilterForm;
