import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';
import NoDataContainer from '../NoDataContainer';
import {getInstanceVersion} from 'redux/modules/instance';
import {
  Button,
  Carousel,
  CareerCard,
  ErrorBoundary,
  RequestErrorLoader,
} from 'mui-core';
import {career} from 'data/dashboard.json';

const {exploreCareersBtnTxt, noCareerSubscriptionResults} = career || {};
const {
  title: noCareerSubscriptionsTitle,
  description: noCareerSubscriptionsDesc,
} = noCareerSubscriptionResults || {};

const ExploreBtn = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  '& .exploreBtn:hover': {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SubscribedCards = ({
  onUnsubscribe,
  savedCareerData: {
    error: subscribedCareerError,
    data: subscribedCareerList = [],
    request: subscribedCareerRequest,
  } = {},
}) => {
  const displayEllipses = [
    {
      title: 'Remove',
      action: onUnsubscribe,
      passingData: ['occupation_name', 'uuid'],
    },
  ];
  const {dashboard: {my_careers: {explore_career = false} = {}} = {}} =
    useSelector(getInstanceVersion) || {};

  return (
    <ErrorBoundary
      typeOfUi='subPage'
      nameOfComponent='module-dashboard-career-careerFavorites-subscribedCareers'>
      <RequestErrorLoader
        minHeight={100}
        body={{
          data: subscribedCareerList,
          request: subscribedCareerRequest,
        }}
        overrideNoDataContainer={
          <NoDataContainer
            featureHidden={explore_career}
            noDataDesc={noCareerSubscriptionsDesc}
            noDataTitle={noCareerSubscriptionsTitle}
          />
        }>
        {!subscribedCareerError &&
          !subscribedCareerRequest &&
          subscribedCareerList?.length > 0 && (
            <>
              <ExploreBtn>
                <Link to='/careers'>
                  <Button variant='outlined' className='exploreBtn'>
                    {exploreCareersBtnTxt}
                  </Button>
                </Link>
              </ExploreBtn>
              <Carousel
                slidesToShow={4}
                content={subscribedCareerList?.map(
                  ({id, cip_code, career_name, cip4_image_cloudinary}) => {
                    const structuredData = {
                      uuid: id,
                      cip_code: cip_code,
                      banner_cloudinary: cip4_image_cloudinary,
                      occupation_details: {occupation_name: career_name},
                    };
                    return (
                      <Box p={2}>
                        <CareerCard
                          key={id}
                          enableNavigation
                          data={structuredData}
                          customClass='sliderCard'
                          displayEllipses={displayEllipses}
                        />
                      </Box>
                    );
                  },
                )}
              />
            </>
          )}
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

SubscribedCards.propTypes = {
  onUnsubscribe: PropTypes.func.isRequired,
  savedCareerData: PropTypes.object.isRequired,
};

export default SubscribedCards;
