import React, {useCallback, useEffect, useMemo, useState} from 'react';
// hooks
// import {useInstance} from 'core/hooks';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
// redux
import {
  fetchCareerAreaList,
  getAllCareerAreaList,
} from 'redux/modules/programs';
import {
  getCareerRank,
  clearCareerRank,
  fetchOccupationRank,
} from 'redux/modules/career-destination';
// Components
import {
  CareerList,
  FilterForm,
} from './subModules/CareerListContainer/components';
import {
  Box,
  alpha,
  Stack,
  Button,
  styled,
  Tooltip,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
  tooltipClasses,
  ClickAwayListener,
} from '@mui/material';
import {VideoBanner, LayoutContainer, themeColorMode} from 'mui-core';
import {InfoIconwhite, goAIButtonIcon, careerVideoBanner} from 'assets/images';
// utils
import {capitalize} from 'lodash';
import {exploreCareer} from 'data/exploreCareer.json';

const {
  careerBannerInfo: {
    ribbonDesc,
    subHeading1,
    ribbonTitle,
    ribbonBtnText,
    bannerHeading,
  },
  FilterOptions,
  defaultLocation,
} = exploreCareer;

const StyledRibbon = styled(Stack)(({theme}) => ({
  zIndex: 1,
  bottom: 0,
  width: '100%',
  position: 'absolute',
  justifyContent: 'center',
  backgroundColor: alpha(theme.palette.primary.darker, 0.8),
}));

const StyledInfoRounded = styled('img')(({}) => ({
  marginLeft: 3,
  width: 15,
  height: 15,
  cursor: 'pointer',
}));

const sortOptions = [
  {
    title: 'A - Z',
    value: 'name_asc',
  },
  {
    title: 'Z - A',
    value: 'name_desc',
  },
];

const defaultParams = {
  page: 1,
  title: '',
  msa_code: '',
  sort: 'name_asc',
  cluster_uuid: 'all',
  location: defaultLocation,
  selectedTab: FilterOptions[0],
};
const tabKeys = ['', 'in-demand', 'highest-paying'];

const ExploreCareer = ({gSearch = ''}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const {activeView = ''} = useParams();
  const [filters, setFilters] = useState({});
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [reqParams, setReqParams] = useState(defaultParams);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  //redux
  const careerRank = useSelector(getCareerRank) || {};
  const careerAreaList = useSelector(getAllCareerAreaList) || {};
  const {data = {}, request: requestCareerRank} = careerRank || {};
  const {
    data: careerRankList = [],
    page_details: careerRankPageDetails = {},
  } = data;
  const recordsCount = careerRankList?.length || 0;
  const totalCount = careerRankPageDetails?.total_results || 0;
  /* Commenting out as requirement changed for default location as Uinted state */
  // const {
  //   data: {
  //     nearby_msa = [],
  //     super_type_name = null,
  //     default_msa: {city = '', state_code = '', msa_code = ''} = {},
  //   } = {},
  // } = useInstance();
  // const defaultLoc = city ? `${city}, ${state_code}` : defaultLocation;
  // const defaultParams = {
  //   page: 1,
  //   title: '',
  //   cluster_uuid: 'all',
  //   location: defaultLoc,
  //   msa_code: msa_code || '',
  //   selectedTab: FilterOptions[0],
  // };

  useEffect(() => {
    if (tabKeys?.includes(activeView)) {
      dispatch(fetchCareerAreaList());
    } else {
      history.push(`/careers`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    /* Commenting out as requirement changed for default location as Uinted state */
    // if (super_type_name && msa_code) {
    //   const defaultLoc = city && state_code && `${city}, ${state_code}`;
    //   newParams = {...newParams, msa_code, location: defaultLoc};
    // } else {
    //   newParams = {...newParams, location: defaultLocation};
    // }
    let newParams = {...reqParams, ...defaultParams};
    if (gSearch) {
      newParams = {...newParams, title: gSearch, location: defaultLocation};
    }
    refetchData(newParams);
    return () => {
      dispatch(clearCareerRank());
    };
  }, [activeView, gSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const refetchData = (Obj, loadMore) => {
    let updatedFilters = {...filters, ...Obj};
    if (Obj?.location === '') {
      updatedFilters = {...updatedFilters, location: defaultLocation};
    }
    if (activeView) {
      const careerType = FilterOptions?.find(
        item => item?.route === activeView,
      );
      updatedFilters = {
        ...updatedFilters,
        selectedTab: careerType,
      };
    }
    setFilters(updatedFilters);
    setReqParams(updatedFilters);
    dispatch(fetchOccupationRank(updatedFilters, loadMore));
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const onDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const onFilterReset = resetObj => {
    const Obj = {...reqParams, ...resetObj};
    refetchData(Obj);
    onDrawerClose();
  };

  const onFinish = useCallback(
    values => {
      let filterApply = false;
      if (values) {
        const filterKeys = Object.keys(values);
        filterKeys.forEach(key => {
          if (values[key] || values[key] === '') {
            filterApply = true;
          }
        });
        if (filterApply) {
          refetchData(values);
          onDrawerClose();
        }
      }
    },
    [reqParams, filters], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const tabs = useMemo(() => {
    return [
      {
        key: '',
        tabName: 'All',
        component: (
          <Box pt={2}>
            <CareerList
              reqParams={reqParams}
              careerRank={careerRank}
              refetchData={refetchData}
            />
          </Box>
        ),
      },
      {
        key: 'in-demand',
        tabName: 'In-Demand',
        component: (
          <Box pt={2}>
            <CareerList
              reqParams={reqParams}
              careerRank={careerRank}
              refetchData={refetchData}
            />
          </Box>
        ),
      },
      {
        key: 'highest-paying',
        tabName: 'Highest Paying',
        component: (
          <Box pt={2}>
            <CareerList
              reqParams={reqParams}
              careerRank={careerRank}
              refetchData={refetchData}
            />
          </Box>
        ),
      },
    ];
  }, [careerRank, activeView]); // eslint-disable-line react-hooks/exhaustive-deps

  const onTabChange = (e, path) => {
    if (tabKeys.includes(path)) {
      history.push(`/careers/${path}`);
    } else {
      history.push(`/careers`);
    }
  };

  const onSortChange = value => {
    const newParams = {...reqParams, sort: value};
    refetchData(newParams);
  };

  const handleJoinNowClick = () => {
    history.push(`/GOSurvey`);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(prev => !prev);
  };

  const RenderBannerRibbon = () => (
    <StyledRibbon sx={{height: {xs: '100px', sm: '80px', md: '100px'}}}>
      <Container maxWidth='xl'>
        <Stack
          spacing={{xs: 0.5, md: 0}}
          justifyContent='space-between'
          direction={{xs: 'column', sm: 'row'}}
          alignItems={{xs: 'center', md: 'center'}}>
          <Typography
            variant='h3'
            color='white'
            fontSize={{xs: '20px', sm: '25px', lg: '30px'}}
            minWidth={{xs: '190px', sm: '228px', lg: '265px'}}>
            {ribbonTitle}
            {isMobile && (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  arrow
                  open={openTooltip}
                  onClose={handleTooltipClose}
                  title={
                    <Typography variant='pagedesc' color='text.primary'>
                      {ribbonDesc}
                    </Typography>
                  }
                  PopperProps={{
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        padding: 2,
                        boxShadow: theme.shadows[3],
                        color: themeColorMode(theme, 'black', 'white'),
                        backgroundColor: themeColorMode(
                          theme,
                          'white',
                          theme.palette.darkGray.main,
                        ),
                      },
                    },
                  }}>
                  <StyledInfoRounded
                    size='small'
                    sx={{ml: 1.5}}
                    alt='info-icon'
                    src={InfoIconwhite}
                    onClick={handleTooltipOpen}
                  />
                </Tooltip>
              </ClickAwayListener>
            )}
          </Typography>
          {!isMobile && (
            <Typography color='white' variant='pagedesc'>
              {ribbonDesc}
            </Typography>
          )}
          <Button
            variant='contained'
            onClick={handleJoinNowClick}
            endIcon={<img src={goAIButtonIcon} width={16} />}
            sx={{
              padding: {xs: '4px 12px', lg: '12px 16px'},
              minWidth: {xs: '114px', md: '200px', lg: '324px'},
            }}>
            {ribbonBtnText}
          </Button>
        </Stack>
      </Container>
    </StyledRibbon>
  );

  const dataSet = {
    data: careerRankList,
    request: requestCareerRank,
  };

  return (
    <LayoutContainer
      dataSet={dataSet}
      typeOfUi='fullPage'
      showSort={!gSearch}
      showSearch={!gSearch}
      activeTab={activeView}
      showDivider={!gSearch}
      disableBGcolor={!!gSearch}
      tabArr={gSearch ? [] : tabs}
      showFilterBtn={gSearch ? !!recordsCount : true}
      nameOfComponent={!gSearch ? 'explore careers' : 'career searches'}
      banner={
        gSearch ? null : (
          <VideoBanner
            bannerURL={careerVideoBanner}
            customRibbon={<RenderBannerRibbon />}>
            <Stack gap={{xs: 1}}>
              <Typography color='white' variant='title55'>
                {bannerHeading}
              </Typography>
              <Typography color='white' variant='bodyL'>
                {subHeading1}
              </Typography>
            </Stack>
          </VideoBanner>
        )
      }
      searchResultProps={{
        totalCount: totalCount,
        recordsCount: recordsCount,
      }}
      onChange={(e, index) => onTabChange(e, index)}
      searchProps={{
        filters,
        onFinish,
        onDrawerOpen,
        onFilterReset,
        name: 'title',
        searchPlaceholder: 'Search by career name',
      }}
      sortProps={{
        onSortChange,
        sortArr: sortOptions,
        sort: reqParams?.sort,
      }}
      filterForm={
        <FilterForm
          filters={filters}
          onFinish={onFinish}
          isDrawerOpen={isDrawerOpen}
          onDrawerClose={onDrawerClose}
          onFilterReset={onFilterReset}
          careerAreaList={careerAreaList}
        />
      }
      title={
        gSearch ? '' : `Explore ${capitalize(activeView) || 'All'} Careers`
      }
      subTitle={
        gSearch
          ? ''
          : 'Browse a comprehensive list of career paths to find the one that aligns with your skills and goals'
      }>
      {gSearch && (
        <CareerList
          reqParams={reqParams}
          careerRank={careerRank}
          refetchData={refetchData}
        />
      )}
    </LayoutContainer>
  );
};

export default ExploreCareer;
