// Get All mdoules here for AppRoutes Linking
import {
  Home,
  ExplorePrograms,
  Search,
  Cluster,
  Institutions,
  GlobalSearch,
  // Pathways,
  //ComparePaths,
  Occupation,
  Programs,
  OccupationDetails,
  BusinessPartners,
  LocalSalary,
  Jobs,
  NewJobs,
  Settings,
  Dashboard,
  Profile,
  ProfilePreview,
  CareerFavorites,
  Education,
  Skills,
  DashboardJobs,
  Workforce,
  CareerInterestSurvey,
  MyEvents,
  ResetPassword,
  StudentProfile,
  Directory,
  PathsList,
  CareerDestination,
  Events,
  Survey,
  MySurvey,
  SurveyHome,
  CareerSurvey,
  MyCareerSurvey,
  CareerSurveyHome,
  ClusterArea,
  BecomeAPartner,
  PartnerDetails,
  PartnersList,
  TermsOfUse,
  PrivacyPolicy,
  Verify,
  GOSurveyDetails,
  AccessibilityStatement,
  MySettings,
  ProfileSetting,
  Apprenticeships,
  Companies,
  ExploreCareer,
  CareerDetails,
  CompanyDetails,
  ProgramDetails,
  JobOpportunities,
  EducationPrograms,
  OpportunityDetails,
  ApprenticeshipDetails,
  //new
  WhoIsHiring,
  WhoIsHiringDetails,
} from 'app/modules';
import GOResume from 'app/modules/go-resume';
import GOSurvey from 'app/modules/go-survey';
import SurveyPage from 'app/modules/go-survey/SurveyPage';
import Talent from 'app/modules/talent-exchange';
import ResumeBuilder from 'app/modules/resume-builder';
import {DashBoardModules} from 'app/modules/dashboard';
import {NewDashboardModules} from 'app/modules/newDashboard';
import Partners from 'app/modules/partners';
import {EmployerPartners} from 'app/modules/partners/submodules';

import {
  facebook,
  instagram,
  linkedin,
  dash_home,
  dash_profile,
  dash_education,
  dash_workforce,
  dash_event,
  dash_skill,
  dash_job,
  resourceCardCareer,
  resourceCardLocalJobOpp,
  resourceCardStudentExplore,
  resourceCardExplorePrograms,
  resourceCardJobSeeker,
  resourceCardBuildProfile,
  resourceCardSearchevents,
  resourceCard2,
  resourceCardPartner,
  resourceCardEmployerPartner,
  resourceCardEducationPartner,
  settingsGrey,
  profileblack,
  profilewhite,
  jobmatchesblack,
  jobmatcheswhite,
  skillsblack,
  skillswhite,
  programsblack,
  appliedblack,
  appliedwhite,
  careerinterestblack,
  careerinterestwhite,
  eventsblack,
  eventswhite,
  careerfavblack,
  careerfavwhite,
} from 'assets/images';
import {
  ProfileIcon,
  StarIcon,
  SkillIcon,
  JobIcon,
  EventIcon,
  CareerIcon,
} from 'mui-core/Icons';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import HomeIcon from '@mui/icons-material/Home';
import PollIcon from '@mui/icons-material/Poll';
import SchoolIcon from '@mui/icons-material/School';
import ApartmentIcon from '@mui/icons-material/Apartment';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EngineeringIcon from '@mui/icons-material/Engineering';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

//Environment Configurations
const SRM = process.env.REACT_APP_SRM;
export const ENV = process.env.REACT_APP_ENV;
const GPS = process.env.REACT_APP_GPS;
const CDN = process.env.REACT_APP_CDN;
const DOMAIN = process.env.REACT_APP_DOMAIN;
export const SUPPORT_MAIL = process.env.REACT_APP_SUPPORT_MAIL;
const CLOUDINARY_API = process.env.REACT_APP_CLOUDINARY_API;
export const CORPORATE_DOMAIN = process.env.REACT_APP_CORPORATE_DOMAIN;
export const HELP_WIDGET_ID = process.env.REACT_APP_HELP_WIDGET_ID;
export const UPLOAD_PRESET = process.env.REACT_APP_UPLOAD_PRESET;
export const GTAG_ID = process.env.REACT_APP_GTAG_ID;
export const IMG_CLOUDINARY =
  process.env.REACT_APP_CLOUDINARY_API_GET + 'image/upload';
export const CAREER_ONE_STOP_IMAGE_BASE_PATH =
  'https://cdn.careeronestop.org/OccVids/OccupationVideos/';

export const EMPLOYER_CONTACT_MAILTO = 'mailto:contactus@goeducate.com';
export const JSON_HEADER = {'Content-Type': 'application/json'};
export const JWT_HEADER = token => ({Authorization: `Bearer ${token}`});
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const DEFAULT_PAGE_SIZE = 12;
export const MUI_X_PRO_KEY = process.env.REACT_APP_MUI_X_KEY;

console.log('ENV', ENV);

export const API = {
  srm: {
    acheivement_options: `${SRM}/gps/acheivement-options/`,
    compare_path: `${GPS}/v2/compare_path/`,
    courses: `${SRM}/gps/courses/`,
    clusters: `${SRM}/gps/cluster-v2/`,
    gened_options: `${SRM}/gps/gened/`,
    institute: `${SRM}/gps/institute/`,
    job_zone_details: `${SRM}/gps/job_zone_details/`,

    job_boards: `${SRM}/gps/career_one_stop_v2/`,

    local_jobs: `${SRM}/gps/business-partner/jobs/`,
    applied_jobs: `${SRM}/business_partner/jobs/`,
    occupations: `${SRM}/gps/occupation/`,
    occupation_roles: `${SRM}/gps/occupation-roles/`,
    occupation_subscribe: `${SRM}/subscribe/`,
    onboarding_path: `${SRM}/gps/onboarding-path`,
    onboarding_type: `${SRM}/onboarding-type`,
    pathways: `${SRM}/gps/pathways-v2/`,
    pathway_details: `${SRM}/gps/publish-v1/`,
    pathway_planner: `${SRM}/pathway-planner/`,
    program: `${SRM}/gps/program/`,
    program_duration: `${SRM}/gps/program-duration/`,
    programs: `${SRM}/gps/business-partner/program`,
    opportunity: `${SRM}/gps/business-partner/opportunity/`,
    search_path_occupation: `${SRM}/gps/search_path_occupation/`,
    program_of_study_list: `${SRM}/gps/pos-path`,
    skills: `${SRM}/gps/skills/`,
    student_survey: `${SRM}/student-survey/`,
    signed_programs: `${SRM}/business_partner/program/sign-up`,
    license: `${SRM}/gps/license/`,
    educators: `${SRM}/v1/super-user/institute/gps/?show=institution`,
    institution_subtypes: `${SRM}/gps/institute_type/?super_type=institution`,
    occupation_rank: `${SRM}/gps/occupation-rank`,
    programs_by_career: `${SRM}/gps/cluster-v2`,
    upcoming_events: `${SRM}/gps/outreach/v1/event-detail`,
    my_signedup_events: `${SRM}/gps/outreach/v1/student-signedup-event-detail`,
    event_signup: `${SRM}/outreach/v1/event_signup`,
    business_partners: `${SRM}/gps/gps-partner`,
    become_business_partner: `${SRM}/gps/institute_request`,
    global_search: `${SRM}/gps/global_suggest`,
    home_metrics: `${SRM}/gps/get_gps_home_page_metrics`,
    allJobs: `${SRM}/gps/search_es`,
    Location: `${SRM}/gps/location_details`,
    student_resume_career_craft: `${SRM}/gps/career_craft/?go_ai=`,
    skills_new: `${SRM}/skills/`,
    industry_sectors: `${SRM}/gps/industry-sectors`,
    nonProfit: `${SRM}/v1/super-user/institute/gps`,
    non_profit_subtypes: `${SRM}/gps/institute_type/?super_type=Nonprofits%20and%20Community%20Organizations`,
  },
  gps: {
    change_email: `${GPS}/v2/change_email/`,
    linkedin_details: `${GPS}/linkedin_details`,
    awards_leadership: `${GPS}/v2/awards_leadership`,
    citizenship: `${GPS}/citizenship/`,
    confirm_forgot_password: `${GPS}/v2/confirm_forgot_password`,
    compare_paths: `${GPS}/v2/compare_path`,
    ethnicity: `${GPS}/race_ethnicity/`,
    education: `${GPS}/v2/education`,
    forgot_password: `${GPS}/v2/forgot_password`,
    login: `${GPS}/v2/login`,
    native_language: `${GPS}/nativelanguage/`,
    refresh_token: `${GPS}/v2/refresh_token/`,
    reset_password: `${GPS}/v2/reset_password/`,
    signup: `${GPS}/v2/signup`,
    confirm_user_account: `${GPS}/v2/confirm_user_account/`,
    states: `${GPS}/state`,
    student_onboard: `${GPS}/v2/student_onboard`,
    student_profile: `${GPS}/v2/student_profile`,
    share_student_profile: `${GPS}/v2/share_student_profile`,
    student_metrics: `${GPS}/v2/student_metrics`,
    student_details: `${GPS}/v2/student_details`,
    student_seeking: `${GPS}/student_seeking`,
    student_skills: `${GPS}/v2/student_skill`,
    student_award_type: `${GPS}/student_award_type`,
    assessment_questions: `${GPS}/assessment_questions/`,
    student_cis: `${GPS}/v2/student_cis`,
    save_path: `${GPS}/v2/save_path`,
    volunteer_experience: `${GPS}/v2/volunteer_experience`,
    work_experience: `${GPS}/v2/work_experience`,
    image_upload: `${CLOUDINARY_API}image/upload`,
    opportunity: `${GPS}/v2/bp_opportunity_apply/`,
    public_insight_jobs: `${GPS}/v2/public_insight_jobs/`,
    share_student_credentials: `${GPS}/v2/share_credential/`,
    student_credentials: `${GPS}/v2/credential/`,
    privacysetting: `${GPS}/v2/privacy_settings/`,
    student_resume: `${GPS}/v2/student_resume/`,
    student_path: `${GPS}/v2/student_path`,
    change_password: `${GPS}/v2/change_password/`,
    delete_user_profile: `${GPS}/v2/student_details`,
    public_student_resume: `${GPS}/public_student_resume/`,
    public_privacy_settings: `${GPS}/v2/public_privacy_settings/`,
    cis_questions: `${GPS}/v2/cis_questions/`,
    student_cis_data: `${GPS}/v2/student_cis_data/`,
    saved_careers: `${GPS}/v2/saved_career/`,
    fit_assessment_survey: `${GPS}/v2/fit_assessment`,
  },
};

export const AppRoutes = [
  {
    key: 'home',
    path: '/',
    component: Home,
    exact: true,
  },
  {
    key: 'exploreprograms',
    path: '/exploreprograms',
    component: ExplorePrograms,
    exact: true,
  },
  {
    exact: true,
    key: 'programs',
    path: '/programs/:activeView?',
    component: EducationPrograms,
  },
  {
    exact: false,
    key: 'programs-details',
    component: ProgramDetails,
    path: '/program-details/:programName/:programId/:view?',
  },
  {
    key: 'reset-password',
    path: '/reset-password',
    component: ResetPassword,
    exact: true,
  },
  {
    key: 'verify',
    path: '/verify',
    component: Verify,
    exact: true,
  },
  {
    exact: false,
    key: 'cluster',
    path: '/cluster',
    component: Search,
    // component: Cluster,
  },
  {
    exact: false,
    key: 'educator',
    component: Search,
    path: '/educator',
  },
  {
    exact: false,
    key: 'institutions',
    path: '/institutions',
    component: Institutions,
  },
  {
    exact: false,
    key: 'search',
    path: '/search',
    component: GlobalSearch, // Search
  },
  // {
  //   key: 'compare',
  //   path: '/compare-paths',
  //   component: ComparePaths,
  //   exact: false,
  // },
  // {
  //   key: 'pathway',
  //   path: '/pathway/:collegeName/:pathName/:awardType/:programUuid',
  //   component: Pathways,
  //   exact: false,
  // },
  {
    key: 'occupation',
    path: '/occupation',
    component: Occupation,
    exact: false,
  },
  {
    key: 'programs',
    path: '/programs/:programId/:institutionName?',
    component: Programs,
    exact: false,
  },
  {
    key: 'occupation-details',
    path: '/occupation-details',
    component: OccupationDetails,
    exact: false,
  },
  {
    key: 'job-opportunities',
    path: '/job-opportunities',
    component: JobOpportunities,
    exact: false,
  },
  {
    key: 'go-resume',
    path: '/GOResume',
    component: GOResume,
    exact: true,
  },
  {
    key: 'resume-builder',
    path: '/resume-builder',
    component: ResumeBuilder,
    exact: true,
    protected: true,
  },
  {
    exact: false,
    path: '/careers/:activeView?',
    key: 'check-out-careers',
    component: ExploreCareer,
  },
  // {
  //   exact: true,
  //   key: 'career-details',
  //   component: CareerDetails,
  //   path: '/careers/:careerName/',
  // },
  {
    exact: false,
    protected: false,
    key: 'career-details',
    component: CareerDetails,
    path: '/career-details/:career_name/:cip_code',
  },
  {
    key: 'local-salary',
    path: '/local-salary',
    component: LocalSalary,
    exact: false,
  },
  {
    key: 'jobs',
    path: '/opportunity/:jobType/:jobId/:institutionName?',
    component: OpportunityDetails,
    exact: false,
  },
  {
    key: 'student-profile',
    path: '/build/student-profile',
    component: StudentProfile,
    exact: false,
  },
  {
    key: 'events',
    path: '/events',
    component: Events,
    exact: false,
  },
  {
    key: 'featured_employers',
    path: '/localemployers',
    component: PartnersList,
    exact: false,
  },
  // {
  //   key: 'companies',
  //   path: '/companies',
  //   component: Companies,
  //   exact: true,
  // },
  // {
  //   key: 'companies',
  //   path: '/companies/:bpName',
  //   component: CompanyDetails,
  //   exact: true,
  // },
  {
    key: 'who-is-hiring',
    path: '/whos-hiring/:activeView?',
    component: WhoIsHiring,
    exact: true,
  },
  {
    key: 'who-is-hiring',
    path: '/communities/:community/:view?',
    component: WhoIsHiringDetails,
    exact: true,
  },
  {
    key: 'who-is-hiring',
    path: '/companies/:company/:view?',
    component: WhoIsHiringDetails,
    // exact: true,
  },
  // {
  //   key: 'apprenticeships',
  //   path: '/opportunities/apprenticeships',
  //   component: Apprenticeships,
  //   exact: true,
  // },
  // {
  //   key: 'apprenticeshipDetails',
  //   path:
  //     '/opportunities/apprenticeships/:apprenticeshipName/:institutionName?',
  //   component: ApprenticeshipDetails,
  //   exact: false,
  // },
  // {
  //   key: 'jobs',
  //   path: '/opportunities/jobs',
  //   component: NewJobs,
  //   exact: true,
  // },
  {
    key: 'apprenticeships',
    path: '/apprenticeships',
    component: Apprenticeships,
    exact: true,
  },
  {
    key: 'apprenticeship-Details',
    path: '/apprenticeships/:institutionName/:apprenticeshipName?',
    component: ApprenticeshipDetails,
    exact: false,
  },
  {
    key: 'jobs',
    path: '/jobs',
    component: NewJobs,
    exact: true,
  },
  {
    key: 'business-partners',
    path: '/business-partners',
    component: BusinessPartners,
    exact: false,
    subModules: [
      {
        key: 'business-partners-list',
        path: '/',
        component: PartnersList,
        exact: true,
        label: 'Discover Career Interests',
      },
      {
        key: 'become-a-business-partner',
        path: '/apply',
        component: BecomeAPartner,
        exact: true,
        label: 'Become an Employer Partner',
      },
      {
        key: 'business-partner-details',
        path: '/:bpName',
        component: PartnerDetails,
        exact: true,
        label: 'Discover Career Interests',
      },
    ],
  },
  {
    exact: true,
    key: 'GOSurvey',
    path: '/GOSurvey',
    component: GOSurvey,
  },
  {
    exact: true,
    protected: true,
    key: 'go-survey',
    path: '/go-survey',
    component: SurveyPage,
  },
  {
    exact: false,
    key: 'survey',
    path: '/career-survey',
    component: CareerSurvey,
    subModules: [
      {
        path: '/',
        exact: true,
        key: 'my-survey-home',
        component: CareerSurveyHome,
      },
      {
        exact: true,
        protected: true,
        key: 'my-survey',
        path: '/my-career-survey',
        component: MyCareerSurvey,
      },
      {
        exact: true,
        protected: true,
        key: 'cluster-area',
        path: '/cluster-area/:clusterName',
        component: ClusterArea,
      },
    ],
  },
  {
    key: 'survey',
    path: '/survey',
    component: Survey,
    exact: false,
    subModules: [
      {
        key: 'my-survey-home',
        path: '/',
        component: SurveyHome,
        exact: true,
        label: 'Discover Career Interests',
      },
      {
        key: 'my-survey',
        path: '/my-survey',
        component: MySurvey,
        protected: true,
        exact: true,
        label: 'Discover Career Interests',
      },
    ],
  },
  {
    exact: false,
    protected: true,
    key: 'dashboard',
    path: '/dashboard',
    component: NewDashboardModules.DashBoardContainer,
    subModules: [
      {
        path: '/',
        key: 'home',
        exact: true,
        label: 'Home',
        component: NewDashboardModules.Home,
      },
      {
        exact: true,
        key: 'opportunities',
        path: '/opportunities',
        label: 'Opportunities',
        component: NewDashboardModules.Opportunities,
      },
      {
        exact: true,
        key: 'career',
        path: '/career',
        label: 'Career',
        component: NewDashboardModules.Career,
      },
      {
        exact: true,
        key: 'go_resume',
        label: 'GoResume',
        path: '/goResume',
        component: NewDashboardModules.GoResume,
      },
      {
        exact: true,
        key: 'settings',
        label: 'Settings',
        path: '/settings',
        component: NewDashboardModules.Settings,
      },
    ],
  },
  {
    exact: false,
    protected: false,
    path: '/oldDashboard',
    key: 'old_dashboard',
    component: DashBoardModules.DashBoardContainer,
    subModules: [
      {
        key: 'my_dashboard',
        path: '/',
        component: DashBoardModules.Dashboard,
        exact: true,
        label: 'My Dashboard',
        icon: StarIcon,
        activeIcon: StarIcon,
      },
      {
        key: 'my_profile',
        path: '/profile',
        component: DashBoardModules.MyProfile,
        exact: false,
        label: 'My Profile',
        icon: ProfileIcon,
        activeIcon: ProfileIcon,
        subModules: DashBoardModules.MyProfileSubModules,
      },
      {
        key: 'my_opportunities',
        path: '/opportunities',
        component: DashBoardModules.MyOpportunities,
        exact: true,
        label: 'My Opportunities',
        icon: JobIcon,
        activeIcon: JobIcon,
      },
      {
        key: 'my_skills',
        path: '/skills',
        component: DashBoardModules.MySkills,
        exact: true,
        label: 'My Skills',
        icon: SkillIcon,
        activeIcon: SkillIcon,
      },
      {
        key: 'my_career',
        path: '/career',
        component: DashBoardModules.MyCareer,
        exact: true,
        label: 'My Career',
        icon: CareerIcon,
        activeIcon: CareerIcon,
      },
      {
        key: 'my_events',
        path: '/events',
        component: DashBoardModules.MyEvents,
        exact: true,
        label: 'My Events',
        icon: EventIcon,
        activeIcon: EventIcon,
      },
      {
        key: 'my_resume',
        path: '/resumes',
        component: DashBoardModules.MyResume,
        exact: true,
        label: 'My Resume',
        icon: BallotOutlinedIcon,
        activeIcon: BallotOutlinedIcon,
      },
    ],
  },
  {
    key: 'previewProfile',
    path: '/profile/:userName',
    component: NewDashboardModules.PreviewProfile,
    exact: true,
  },
  {
    key: 'settings',
    path: '/settings',
    component: Settings,
    exact: false,
    protected: true,
    subModules: [
      // {
      //   key: 'dashboard',
      //   path: '/dashboard',
      //   // component: Dashboard,
      //   component: Profile,
      //   exact: true,
      //   label: 'My Dashboard',
      //   icon: dash_home,
      // },
      {
        key: 'profile',
        path: '/profile',
        component: Profile,
        exact: true,
        label: 'My Profile',
        icon: profileblack,
        activeIcon: profilewhite,
      },
      {
        key: 'jobs',
        path: '/opportunities',
        component: DashboardJobs,
        exact: true,
        label: 'My Job Matches',
        icon: jobmatchesblack,
        activeIcon: jobmatcheswhite,
      },
      {
        key: 'skills',
        path: '/skills',
        component: Skills,
        exact: true,
        label: 'My Skills',
        icon: skillsblack,
        activeIcon: skillswhite,
      },
      {
        key: 'education',
        path: '/education',
        component: Education,
        exact: true,
        label: 'My Programs',
        icon: programsblack,
        activeIcon: programsblack,
      },
      {
        key: 'workforce',
        path: '/workforce',
        component: Workforce,
        exact: true,
        label: 'My Applied/Saved Jobs',
        icon: appliedblack,
        activeIcon: appliedwhite,
      },

      {
        key: 'CareerFavorites',
        path: '/CareerFavorites',
        component: CareerFavorites,
        exact: true,
        label: 'My Career Favorites',
        icon: careerfavblack,
        activeIcon: careerfavwhite,
      },
      {
        key: 'events',
        path: '/events',
        component: MyEvents,
        exact: true,
        label: 'My Events',
        icon: eventsblack,
        activeIcon: eventswhite,
      },
      {
        key: 'career-interest-survey',
        path: '/career-interest-survey',
        component: CareerInterestSurvey,
        exact: true,
        label: 'My Career Interest Survey',
        icon: careerinterestblack,
        activeIcon: careerinterestwhite,
      },
      {
        key: 'preview',
        path: '/profile/preview',
        component: ProfilePreview,
        protected: true,
        exact: true,
      },
      {
        key: 'mysettings',
        path: '/mysettings',
        component: MySettings,
        exact: true,
        protected: true,
        label: 'My Settings',

        // icon: settingsGrey,
      },
    ],
  },
  // {
  //   key: 'profilePreview',
  //   path: '/profile/preview/:userName',
  //   component: ProfilePreview,
  //   exact: true,
  // },
  {
    key: 'profilePreview',
    path: '/profile/privacy-Setting',
    component: ProfileSetting,
    exact: true,
    protected: true,
  },
  {
    key: 'directory',
    path: '/directory',
    component: Directory,
    exact: false,
    subModules: [
      {
        key: 'paths-list',
        path: '/paths-list',
        component: PathsList,
        exact: true,
        label: 'Explore Career Pathways',
      },
    ],
  },
  {
    key: 'terms-of-use',
    path: '/terms-of-use',
    component: TermsOfUse,
    exact: true,
  },
  {
    key: 'privacy-policy',
    path: '/privacy-policy',
    component: PrivacyPolicy,
    exact: true,
  },
  {
    key: 'accessibility',
    path: '/accessibility',
    component: AccessibilityStatement,
    exact: true,
  },
  {
    key: 'talent-exchange',
    path: '/talent-exchange',
    component: Talent,
    exact: true,
  },
  {
    key: 'partners',
    path: '/partners',
    component: Partners,
    exact: true,
  },
  {
    exact: true,
    key: 'employer-partner',
    component: EmployerPartners,
    path: '/partners/employer',
  },
];

export const HeaderMenuItems = () => {
  const menus = [
    {
      key: 'DASHBOARD',
      to: '/dashboard',
    },
    {
      key: 'LOGOUT',
    },
  ];
  if (ENV !== 'prod')
    menus.push({
      key: 'OLD_DASHBOARD',
      to: '/settings',
    });
  return menus;
};

export const HeaderMenu = HeaderMenuItems();

export const FooterMenu = [
  {
    key: 'ABOUT_US',
    path: DOMAIN,
    target: '_blank',
  },
  {
    key: 'CONTACT_US',
    path: `mailto:${SUPPORT_MAIL}`,
    target: '_self',
  },
  {
    key: 'ADDRESS',
  },
  {
    key: 'TERMS',
    path: `/terms-of-use`,
    target: '_blank',
  },
  {
    key: 'PRIVACY',
    path: `/privacy-policy`,
    target: '_blank',
  },
];

export const FooterSocialLinks = [
  {
    mediaIcon: linkedin,
    name: 'linkedin',
    link: 'https://www.linkedin.com/company/goeducate/',
    target: '_blank',
  },
  {
    mediaIcon: instagram,
    name: 'instagram',
    link: 'https://www.instagram.com/goeducateinc/',
    target: '_blank',
  },
  {
    mediaIcon: facebook,
    name: 'facebook',
    link: 'https://www.facebook.com/GoEducate-Inc-130365025434596',
    target: '_blank',
  },
];

export const MainMenu = [
  {
    path: '/',
    key: 'home',
    label: 'Home',
    icon: HomeIcon,
  },
  {
    path: '/careers',
    label: 'Careers',
    key: 'discover_careers',
    subTabs: true,
    icon: QueryStatsIcon,
  },
  {
    key: 'go-survey',
    path: '/GOSurvey',
    label: 'GOSurvey',
    icon: PollIcon,
  },
  {
    key: 'go-resume',
    path: '/GOResume',
    label: 'GOResume',
    icon: BallotOutlinedIcon,
  },
  // {
  //   key: 'explore_programs',
  //   path: '/exploreprograms',
  //   label: 'Explore Programs',
  // },
  // {
  //   key: 'find_opportunites',
  //   path: '/business-partners?type=job_boards&page=home',
  //   label: 'Find Jobs',
  // },
  // {
  //   key: 'featured_employers',
  //   path: '/localemployers',
  //   label: 'Local Employers',
  // },
  // {
  //   key: 'job-opportunities',
  //   path: '/job-opportunities',
  //   label: 'Jobs & Opportunities',
  // },

  // {
  //   key: 'opportunities',
  //   path: '/opportunities',
  //   label: 'Jobs & Opportunities',
  //   children: [
  //     {
  //       key: 'jobs',
  //       path: '/jobs',
  //       label: 'Jobs',
  //     },
  //     {
  //       key: 'apprenticeships',
  //       path: '/apprenticeships',
  //       label: 'Apprenticeships',
  //     },
  //   ],
  // },

  {
    key: 'jobs',
    path: '/jobs',
    label: 'Jobs',
    icon: BusinessCenterIcon,
  },
  {
    key: 'apprenticeships',
    path: '/apprenticeships',
    label: 'Apprenticeships',
    icon: EngineeringIcon,
  },
  {
    key: 'programs',
    path: '/programs',
    label: 'Programs',
    subTabs: true,
    icon: SchoolIcon,
  },
  {
    key: 'who-is-hiring',
    path: '/whos-hiring',
    label: 'Who’s Hiring',
    subTabs: true,
    icon: ApartmentIcon,
  },
  // {
  //   key: 'companies',
  //   path: '/companies',
  //   label: 'Community Marketplace',
  // },
  // {
  //   key: 'talent-exchange',
  //   path: '/talent-exchange',
  //   label: 'Talent Exchange',
  // },
  {
    key: 'search_events',
    path: '/events',
    label: 'Events',
    icon: EmojiEventsIcon,
  },
];

export const FooterLinks = [
  {
    key: 'RESOURCES',
    links: [
      {
        key: 'EXPLORE_PROGRAMS',
        link: '/exploreprograms',
        target: '_blank',
      },
      {
        key: 'DISCOVER_INTERESTS',
        link: '/survey',
        target: '_blank',
      },
      {
        key: 'MARKETABLE_PROFILE',
        link: '/build/student-profile',
        target: '_blank',
      },
      {
        key: 'SEARCH_EVENTS',
        link: '/events',
        target: '_blank',
      },
      {
        key: 'CAREERS',
        link: '/occupations',
        target: '_blank',
      },
      {
        key: 'FIND_OPPORTUNITIES',
        link: '/business-partners?type=job_boards&page=home',
        target: '_blank',
      },
      {
        key: 'FEATURED_EMPLOYERS',
        link: '/localemployers',
        target: '_blank',
      },
      {
        key: 'ABOUT',
        link: CORPORATE_DOMAIN,
        target: '_blank',
        anchor: true,
      },
    ],
  },
  {
    key: 'BECOMEAPARTNER',
    isDirectLink: true,
    links: [
      {
        key: 'BECOME_A_PARTNER',
        link: `${CORPORATE_DOMAIN}/employer-partners`,
        target: '_blank',
        display: true,
        anchor: true,
      },
    ],
  },
];

export const FooterMenuItem = [
  {
    key: 'ABOUT_US',
    link: `${CORPORATE_DOMAIN}`,
    target: '_blank',
    anchor: true,
  },
  {
    key: 'CONTACT_US',
    link: `${CORPORATE_DOMAIN}/contact-us`,
    target: '_blank',
    anchor: true,
  },
  {
    key: 'HELP_SUPPORT',
    link: `${CORPORATE_DOMAIN}/resource-center`,
    target: '_blank',
    anchor: true,
  },
  {
    key: 'TERMS',
    link: `/terms-of-use`,
    target: '_self',
    anchor: true,
  },
  {
    key: 'PRIVACY',
    link: `/privacy-policy`,
    target: '_self',
    anchor: true,
  },
  {
    key: 'RESOURCE_CENTER',
    link: `${CORPORATE_DOMAIN}/resource-center`,
    target: '_blank',
    anchor: true,
  },
  {
    key: 'ACCESSIBILITY_STATEMENT',
    link: `/accessibility`,
    target: '_self',
    anchor: true,
  },
];

// 'key' below is the card key in home.json
export const HomePageResources = [
  {
    key: 'STUDENT',
    imgSrc: resourceCardStudentExplore,
    subResources: [
      {
        key: 'EXPLORE_PROGRAMS',
        path: '/exploreprograms',
        alsoDispInDashboard: true,
        imgSrc: resourceCardExplorePrograms,
      },
      {
        path: '/careers',
        key: 'CHECK_OUT_CAREERS',
        alsoDispInDashboard: true,
        imgSrc: resourceCardCareer,
      },
      {
        key: 'DISCOVER_INTERESTS',
        imgSrc: resourceCard2,
        path: '/survey',
        alsoDispInDashboard: true,
      },
    ],
  },
  {
    key: 'JOBSEEKER',
    imgSrc: resourceCardJobSeeker,
    subResources: [
      {
        key: 'FIND_OPPORTUNITIES',
        imgSrc: resourceCardLocalJobOpp,
        path: '/business-partners?type=job_boards&page=home',
        alsoDispInDashboard: true,
      },
      {
        key: 'MARKETABLE_PROFILE',
        imgSrc: resourceCardBuildProfile,
        path: '/build/student-profile',
        alsoDispInDashboard: false,
      },
      {
        key: 'SEARCH_EVENTS',
        imgSrc: resourceCardSearchevents,
        path: '/events',
        alsoDispInDashboard: true,
      },
    ],
  },
  {
    key: 'PARTNER',
    imgSrc: resourceCardPartner,
    subResources: [
      {
        key: 'EMPLOYER_PARTNER',
        imgSrc: resourceCardEmployerPartner,
        path: '/business-partners/apply?partnerType=employer',
        alsoDispInDashboard: false,
      },
      {
        key: 'EDUCATION_PARTNER',
        imgSrc: resourceCardEducationPartner,
        path: '/business-partners/apply?partnerType=educator',
        alsoDispInDashboard: false,
      },
    ],
  },
];

export const LanguageMenu = [
  {
    key: 'ENGLISH',
    label: 'English',
    value: 'en',
  },
  {
    key: 'GERMAN',
    label: 'Deutsch',
    value: 'de',
  },
  {
    key: 'SPANISH',
    label: 'Español',
    value: 'es',
  },
];

//Settings Module Profile AwardsType Dropdown Values
export const awardTypes = [
  {label: 'Professional Certificate', value: 'professional_certificate'},
  {label: 'Associate Degree', value: 'associate_degre'},
  {label: 'Bachelor Degree', value: 'bachelor_degree'},
  {label: 'Graduate Degree', value: 'graduate_degree'},
  {label: 'Master Degree', value: 'master_degree'},
  {label: 'Doctoral Degree', value: 'doctoral_degree'},
  {label: 'Professional Degree', value: 'professional_degree'},
  {label: 'Specialist Degree', value: 'specialist_degree'},
  {label: 'High School Diploma', value: 'high_school_diploma'},
  {label: 'GED', value: 'ged'},
  {label: 'Other', value: 'other'},
];

export const licenseTypeKeys = {
  rop: 'ROP',
  reporter: 'Reporter',
  employer: 'Employer',
  institution: 'Institution',
  criminalJustice: 'Criminal Justice Organization',
  nonprofits: 'Nonprofits and Community Organizations',
};
