import React, {useState, useEffect, useCallback} from 'react';
import {Stack, Divider, Container, Typography} from '@mui/material';
// components
import JobLayout from './Layout';
import FilterForm from './components/FilterForm';
//icons
import BusinessIcon from '@mui/icons-material/Business';
import SuitcaseIcon from '@mui/icons-material/BusinessCenter';
import {useInstance, useOpportunitiesData} from 'core/hooks';
// utils
import {StaticBanner, StatsWithIcon, LayoutContainer} from 'mui-core';
import {jobsBanner} from 'assets/images';
import jobsStaticData from 'data/jobs.json';

const {
  bannerData: {
    ribbonText,
    bannerDesc,
    bannerTitle,
    jobsAvailLabel,
    jobsAvailCount,
    companyHiringLabel,
    companyHiringCount,
  },
  breadcrumbData,
  defaultLocation,
} = jobsStaticData || {};

const defaultParams = {
  page: 1,
  page_size: 16,
  sort: 'date_desc',
};

const Jobs = ({
  title = '',
  company = '',
  subTitle = '',
  tabPage = false,
  search_query = '',
  disableBGcolor = false,
}) => {
  const [filters, setFilters] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [reqParams, setReqParams] = useState(defaultParams);
  const [initialFilters, setInitialFilters] = useState(filters);
  const {jobsData, clearJobs, fetchAllJobs} = useOpportunitiesData();
  const {data: {data: jobsList = []} = {}, request: requestJobsList} =
    jobsData || {};
  const {
    data: {
      nearby_msa = [],
      super_type_name = null,
      default_msa: {city = '', state_code = ''} = {},
    } = {},
  } = useInstance();
  const defaultLoc = super_type_name
    ? `${city ? city + ', ' : ''}${state_code}`
    : defaultLocation;

  const apiCall = (Obj, filters = null) => {
    fetchAllJobs(Obj, filters);
  };

  const refetchData = (Obj, newfFilters, loadMore) => {
    setReqParams(Obj);
    const updatedFilters = {...filters, ...newfFilters};
    setFilters(updatedFilters);
    apiCall(Obj, updatedFilters, loadMore);
  };

  const onPageChange = page => {
    window.scrollTo({top: 660, behavior: 'smooth'});
    const Obj = Object.assign({}, reqParams, {
      page,
    });
    refetchData(Obj, filters, true);
  };

  useEffect(() => {
    let updatedFilters = {
      ...filters,
      location: defaultLoc,
      company_name: company,
    };
    if (search_query) {
      updatedFilters.title = search_query;
    }
    setInitialFilters(updatedFilters);
    refetchData(reqParams, updatedFilters);
    return () => {
      clearJobs();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = useCallback(
    values => {
      let filterApply = false;
      if (values) {
        const filterKeys = Object.keys(values);
        filterKeys.forEach(key => {
          if (values[key] || values[key] === '') {
            filterApply = true;
          }
          if (key === 'no_of_days') {
            values[key] = parseInt(values[key]);
            if (values[key] === 0) {
              values[key] = null;
            }
            filterApply = true;
          }
        });
        if (filterApply) {
          const Obj = Object.assign({}, reqParams, {
            sort: 'datedesc',
            page: 1,
          });
          refetchData(Obj, values);
          onDrawerClose();
        }
      }
    },
    [reqParams, filters], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onFilterReset = resetObj => {
    const Obj = {...defaultParams};
    refetchData(Obj, {...resetObj});
    onDrawerClose();
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const onDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const dataSet = {
    data: jobsList,
    request: requestJobsList,
  };

  return (
    <LayoutContainer
      title={title}
      dataSet={dataSet}
      subTitle={subTitle}
      showDivider={false}
      typeOfUi='fullPage'
      nameOfComponent='jobs'
      showResultCount={false}
      disableBGcolor={disableBGcolor}
      banner={
        !tabPage ? (
          <>
            {/* <Container maxWidth='xl'>
              <AppBreadcrumb showHome={false} dataList={breadcrumbData} />
            </Container> */}
            <StaticBanner
              url={jobsBanner}
              desc={bannerDesc}
              title={bannerTitle}
              ribbonTxt={ribbonText}>
              <Stack direction='row' gap='15px'>
                <StatsWithIcon
                  data={[
                    {
                      title: jobsAvailLabel,
                      count: jobsAvailCount,
                      icon: <SuitcaseIcon style={{color: 'white'}} />,
                    },
                    {
                      title: companyHiringLabel,
                      count: companyHiringCount,
                      icon: <BusinessIcon style={{color: 'white'}} />,
                    },
                  ]}
                />
              </Stack>
            </StaticBanner>
          </>
        ) : null
      }
      searchProps={{
        filters,
        onFinish,
        onDrawerOpen,
        onFilterReset,
        name: 'title',
        onSearch: value => onFinish(value),
        searchPlaceholder: 'Search by job title or other keyword',
      }}
      filterForm={
        <FilterForm
          filters={filters}
          hideCompanyFilter={tabPage}
          initialFilters={initialFilters}
          nearbyMSA={nearby_msa?.map((l, idx) => {
            return {
              ...l,
              id: idx + 1,
              nearby_msa: 'Nearby Locations',
            };
          })}
          onFinish={onFinish}
          isDrawerOpen={isDrawerOpen}
          onDrawerClose={onDrawerClose}
          onFilterReset={onFilterReset}
        />
      }>
      <JobLayout
        reqParams={reqParams}
        pageOptions={defaultParams}
        onPageChange={onPageChange}
      />
    </LayoutContainer>
  );
};

export default Jobs;
