import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {numberInUSFormat} from 'core/utils';
import {
  Box,
  Paper,
  Stack,
  Divider,
  useTheme,
  Typography,
  CardActionArea,
} from '@mui/material';
import {CLOUDINARY} from 'core/cloudinary';
import {AdvancedImage} from '@cloudinary/react';
import {Card, LazyImage, ErrorBoundary} from 'mui-core';

const CompanyLogo = styled(Box)(({theme}) => ({
  '& img': {
    width: 40,
    height: 40,
    maxWidth: '100%',
    objectFit: 'contain',
    borderRadius: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));
const StyledTypography = styled(Typography)(({}) => ({
  overflow: 'hidden',
  color: 'text.primary',
  display: '-webkit-box',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
}));

const renderInfoItem = (infoItems, textColor) => {
  const {Icon, value, count, label = ''} = infoItems || {};
  return (
    <Stack direction='row' spacing={0.5} flexWrap='wrap' alignItems='center'>
      {Icon && (
        <Icon
          sx={{width: 18, height: 18, marginBottom: '4px', color: textColor}}
        />
      )}
      {count >= 0 && label ? (
        <Typography variant='body2' color={textColor}>
          {`${numberInUSFormat(count)} ${label}`}
        </Typography>
      ) : (
        <Typography variant='body2' color={textColor}>
          {value}
        </Typography>
      )}
    </Stack>
  );
};

const CompanyCard = ({
  data,
  width,
  children,
  infoItems,
  verifiedIcon,
  target = '_blank',
  centeredLogo = true,
  enableNavigation = false,
}) => {
  const {
    path,
    title,
    companyLogo,
    companyName,
    companyBanner,
    industrySector: {industryName, IndustrySectorIcon} = {},
    physicalAddress: {address: {city = '', state = ''} = {}, LocationIcon} = {},
  } = data || {};

  const theme = useTheme();
  const history = useHistory();
  const {
    location: {pathname},
  } = history;

  const handleNavigation = () => {
    if (target === '_self' || pathname === '/') {
      history.push(path);
    } else {
      window.open(path, target);
    }
  };

  const themeTextColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey.main
      : theme.palette.grey.contrastText;

  const logoImage = CLOUDINARY.getLogoImage(companyLogo);

  return (
    <ErrorBoundary nameOfComponent='CompanyCard'>
      <Card
        sx={{
          mb: 2,
          width,
          textAlign: 'left',
          position: 'relative',
          display: 'inline-block',
        }}
        component={CardActionArea}
        onClick={() => enableNavigation && handleNavigation()}>
        <Box height={200}>
          <LazyImage renderSrcSet alt='company-banner' src={companyBanner} />
        </Box>
        {centeredLogo && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Paper
              sx={{
                width: 200,
                height: 60,
                padding: '5px',
                display: 'flex',
                marginTop: '-30px',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <AdvancedImage
                width='auto'
                height='100%'
                cldImg={logoImage}
                alt={`${companyName}-Company-logo`}
                style={{maxWidth: '100%', objectFit: 'contain'}}
              />
            </Paper>
          </Box>
        )}
        <Stack m={2} spacing={1} height={centeredLogo ? 90 : 110}>
          {title && (
            <Typography
              noWrap
              variant='h6'
              title={title || ''}
              color='text.primary'
              textTransform='capitalize'>
              {title}
            </Typography>
          )}
          <Stack direction='row' spacing={1} alignItems='center'>
            {!centeredLogo && (
              <CompanyLogo>
                <LazyImage
                  src={companyLogo}
                  alt='company-logo'
                  imageType='cardLogo'
                />
              </CompanyLogo>
            )}
            {companyName && (
              <>
                <StyledTypography
                  variant='h7'
                  textTransform='capitalize'
                  title={companyName || ''}>
                  {companyName}
                </StyledTypography>
                {verifiedIcon && (
                  <Box>
                    <img
                      width={18}
                      height={18}
                      src={verifiedIcon}
                      alt='verified Checkmark icon'
                    />
                  </Box>
                )}
              </>
            )}
          </Stack>
          {industryName && (
            <Stack direction='row' mt={1} spacing={1} alignItems='center'>
              <IndustrySectorIcon sx={{color: theme.palette.primary.light}} />
              <Typography
                noWrap
                variant='body2'
                mt='4px !important'
                color={themeTextColor}
                title={industryName || ''}>
                {industryName || ''}
              </Typography>
            </Stack>
          )}
          {city && state && (
            <Stack direction='row' mt={1} spacing={1} alignItems='center'>
              <LocationIcon sx={{color: theme.palette.primary.light}} />
              <Typography
                noWrap
                variant='body2'
                color={themeTextColor}
                textTransform='capitalize'
                title={`${city}, ${state}`}>
                {`${city}, ${state}`}
              </Typography>
            </Stack>
          )}
        </Stack>
        {(infoItems || children) && <Divider sx={{borderStyle: 'dashed'}} />}
        {infoItems && (
          <Stack
            mx={2}
            direction='row'
            sx={{height: 60}}
            spacing={infoItems?.length > 1 ? 3 : 0}>
            {infoItems &&
              Array.isArray(infoItems) &&
              infoItems?.length > 0 &&
              infoItems.map(infoItems =>
                renderInfoItem(infoItems, themeTextColor),
              )}
          </Stack>
        )}
        {children}
      </Card>
    </ErrorBoundary>
  );
};

CompanyCard.propTypes = {
  data: PropTypes.object,
  width: PropTypes.number,
  enableNavigation: PropTypes.bool,
};

export default CompanyCard;
